<template>
    <div
        class="preview"
        :class="{'full-width': globalMenuIsOpen}">
        <div class="preview__header">
            <div
                class="preview__back"
                @click="goBack">
                &lt; Back to results
            </div>
            <div
                v-if="!loading"
                class="edit-button">
                <action-button
                    icon="edit"
                    size="19"
                    @click="editAd(currentAd)">
                    <b>Edit</b>
                </action-button>
            </div>
        </div>
        <div v-if="loading">
            <loader />
        </div>
        <div v-else>
            <div class="preview__main">
                <div class="preview__left">
                    <div class="preview__img">
                        <img
                            v-if="adType === 'IMAGE'"
                            :src="adUrl"
                            alt="">
                        <video
                            v-if="adType === 'VIDEO'"
                            muted
                            autoplay
                            loop>
                            <source
                                :src="adUrl"
                                type="video/mp4">
                        </video>
                    </div>
                    <div class="preview__name">
                        {{ currentAd.display_name }}
                    </div>
                </div>
                <div class="preview__right">
                    <div
                        class="preview__button"
                        @click="deployCurrentAd(currentAd)">
                        <div class="preview__icon">
                            <icon
                                name="opportunity"
                                color="white"
                                size="20" />
                        </div>
                        DEPLOY THIS AD
                    </div>
                </div>
            </div>
            <div class="preview__desc">
                <author-name
                    class="preview__block"
                    :author-id="currentAd.created_by_user_id" />
                <div class="preview__block">
                    Supported Platforms:
                    <div class="preview__platforms">
                        <span
                            v-for="(channel,index) in selectedChannels"
                            :key="index">
                            <icon
                                :name="channel.toLowerCase()"
                                color="#8f9ea6"
                                size="16" />
                        </span>
                    </div>
                </div>
                <div class="preview__block">
                    Created: {{ currentAd.created_at.date | moment('MMM DD, YYYY') }}
                </div>
            </div>
            <div
                class="previews-canvas"
                :class="{'overflow-auto': overflowAuto}">
                <ad-previews
                    :ad-data="currentAd"
                    canvas-view
                    :selected-channels="selectedChannels"
                    @set-view="setView"
                    @fit-activated="resetOverflow" />
            </div>
        </div>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import EventBus from '@/event-bus';
import AdPreviews from '@/components/ad-studio/editing/preview-step/AdPreviews';
import Icon from '@/components/globals/Icon';
import AuthorName from '@/components/globals/AuthorName';
import Loader from '@/components/globals/Loader.vue';
import { mapState } from 'vuex';
import { sleep } from '@/helpers';
import { STATIC_DEPLOYMENT_TYPE } from '@/components/ad-deployment/store/constants';

export default {
    components: {
        ActionButton,
        AdPreviews,
        Loader,
        AuthorName,
        Icon
    },
    props: {
        adId: {
            type: [String, Number],
            required: true
        },
    },
    data() {
        return {
            singleView: true,
            loading: true,
            overflowAuto: true,
            currentAd: {}
        };
    },
    computed: {
        ...mapState({
            adsInStudio: state => state.adStudio.ads.length,
            dealer: (state) => state.dealer.currentDealer,
            user: (state) => state.user.user,
            globalMenuIsOpen: (state) => state.settings.globalMenuIsOpen
        }),
        adType() {
            return this.currentAd.full_configuration?.all_platforms?.creatives[0].assets[0].asset_type.name.toUpperCase() ??
                this.currentAd.configuration?.all_platforms?.creatives[0].assets[0].asset_type.name.toUpperCase();
        },
        adUrl() {
            return this.currentAd.full_configuration?.all_platforms?.creatives[0].assets[0].url ?? this.currentAd.configuration?.all_platforms?.creatives[0].assets[0].url;
        },
        selectedChannels() {
            return this.currentAd.full_configuration?.selected_platforms ?? this.currentAd.configuration?.selected_platforms;
        }
    },
    watch: {
        adsInStudio() {
            if (this.adsInStudio == 0) {
                this.loading = true;
                this.getAds();
            }
        }
    },
    async mounted() {
        await this.getAds();
    },
    methods: {
        async getAds() {
            try {
                const response = await this.$apiRepository.getAds();
                const allAds = response.data.data;
                const filteredAd = allAds.filter(ad => ad.id == this.adId);
                this.currentAd = filteredAd[0];
            } catch (error) {
                console.error('Error loading ads', error);
            } finally {
                this.loading = false;
            }
        },
        deployCurrentAd(adData) {
            const ads = [];
            ads.push(adData);

            EventBus.$emit('open-deployment-modal', {
                configuration: { ads },
                userId: this.user.id,
                dealerId: this.dealer.id,
                deployment_type: STATIC_DEPLOYMENT_TYPE
            });
        },
        editAd(ad) {
            const ads = [ad];
            this.$store.commit('adStudio/setAllAds', { ads });
            this.$store.commit('adStudio/setEditingExistingAd', true);
            EventBus.$emit('open-ad-studio', { editMode: true });
        },
        setView(view) {
            this.singleView = view;
        },
        goBack() {
            this.$router.go(-1);
        },
        async resetOverflow() {
            // Fixing weird Safari browser bug (CSS 'overflow: auto' not working properly with dynamically scaled content)
            this.overflowAuto = null;
            await sleep(100);
            this.overflowAuto = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.preview{
    position: relative;
    z-index: 2;
    width: calc(100% + 48px);
    top:-24px;
    left:-24px;
    right:-24px;
    bottom:-24px;
    height: calc(100vh - 150px);
    @media only screen and (max-width: 959px) {
        width: calc(100% + 32px);
        top:-16px;
        left:-16px;
        right:-16px;
        bottom:-16px;
    }
    &__header{
        padding: 10px 45px 10px 30px;
        border-bottom: 1px solid #D9D9D9;
        background: #EDF2F5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 61.5px;
    }
    &__back{
        color: #00A4EB;
        cursor: pointer;
    }
    &__img{
        margin-left: 45px;
        height: 75px;
        display: flex;
        align-items: center;
        img,video{
            max-height: 100%;
            width: auto;
        }
    }
    &__main{
        padding: 20px 0;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        background: #EDF2F5;
    }
    &__left{
        display: flex;
        align-items: center;
    }
    &__right{
        display: flex;
        align-items: center;
    }
    &__name{
        margin-left: 45px;
        font-size: 24px;
        color: #00A4EB;
    }
    &__button{
        margin-right: 45px;
        padding: 18px 45px;
        padding-left: 75px;
        background: #7FD41B;
        color: white;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
    }
    &__icon{
        position: absolute;
        top: 18px;
        left: 18px;
    }
    &__desc{
        display: flex;
        background: #EDF2F5;
        padding-left: 30px;
        font-size: 12px;
        border-bottom: 1px solid #D9D9D9;
        padding-bottom: 30px;
    }
    &__block{
        margin-right: 45px;
        display: inline-flex;
        align-items: center;
        span{
            margin-left: 5px;
        }
    }
    &__platforms{
        display: inline-flex;
        align-items: center;
        svg{
            margin-left: 5px;
        }
    }
    &__square{
        width: 16px;
        height: 16px;
        background: #8f9ea6;
        margin: 0 5px;
    }
    &__portrait{
        width: 12px;
        height: 22px;
        margin: 0 5px;
        background: #8f9ea6;
    }
}
.previews-canvas {
    height: calc(100vh - 320px);
    overflow: hidden;
}
.previews-canvas.overflow-auto {
    overflow: auto;
}
</style>
<style lang="scss">
.preview{
    .pws-wrapper{
        padding-top: 0;
    }
    .pws-header {
        left: 50px;
        right: 0;
        top: 285px;
        padding-right: 45px;
        padding-left: 20px;
        transition: 0.3s;
    }
}
.preview.full-width .pws-header {
    left: 350px;
}
</style>
